import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../img/pink_logo.png';
import searchIcon from '../img/white-search-icon.png';
import burgerIcon from '../img/white-menu-icon.png';
import axios from 'axios';
import config from '../config.json'; 
import '../css/Header.css';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const navRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [cartItemsCount, setCartItemsCount] = useState(0);

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const handleBurgerClick = () => {
        setMenuOpen((prev) => !prev); // Use functional update for state toggle
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClickOutside = (event) => {
        if (navRef.current && !navRef.current.contains(event.target) && !event.target.closest('.burger-icon')) {
            setMenuOpen(false);
        }
    };

    const handleSearchSubmit = async (event) => {
        event.preventDefault();
    
        const query = encodeURIComponent(searchQuery);
        const url = `/catalog/?query=${query}`;
        navigate(url);
    };

    useEffect(() => {
        const cartId = getCartIdFromCookie();

        const fetchItem = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/cart/items-count/?cart_uuid=${cartId}`);
                setCartItemsCount(response.data.cart_items_count);
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchItem();
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getCartIdFromCookie = () => {
        const name = 'cart_id=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <React.Fragment>
            <header>
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} width="60" alt="Store Logo" />
                        </Link>
                    </div>
                    <div className="navbar-container">
                        <form className="search-container" onSubmit={handleSearchSubmit}>
                            <img src={searchIcon} width="25" alt="Search" />
                            <input 
                                type="text" 
                                placeholder="ПОШУК" 
                                className="search-input" 
                                value={searchQuery}  
                                onChange={handleSearchChange}
                            />
                        </form>
                        <nav className={`nav-element ${menuOpen ? 'open' : ''}`} ref={navRef}>
                            <ul>
                                <li className={isActive('/catalog/')}><Link to="/catalog/">Каталог</Link></li>
                                <li><a href="#footer">Про нас</a></li>
                                <li className={isActive('/cart/')}><Link to="/cart/">Кошик <span className='cart-items-count'>{cartItemsCount ? `(${cartItemsCount})` : ''}</span></Link></li>
                            </ul>
                        </nav>
                        <div className="burger-icon" onClick={handleBurgerClick}>
                            <img src={burgerIcon} width="25" alt="Menu" />
                        </div>
                    </div>
                </div>
                <Link to="/"><div className="title">LADY'S CLUB</div></Link>
            </header>
        </React.Fragment>
    );
}

export default Header;
