import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config.json'; 
import '../css/Filters.css';

const Filters = () => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState(new Set());

    useEffect(() => {
        axios.get(`${config.API_BASE_URL}/api/categories/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                setError(error.message);
            });
    }, []);

    useEffect(() => {
        const shopItems = document.querySelectorAll('.shop-item');
        if (selectedCategories.size === 0) {
            shopItems.forEach((item) => {
                item.classList.remove('not-visible');
            });
        } else {
            shopItems.forEach((item) => {
                const category = item.getAttribute('data-category');
                if (selectedCategories.has(category)) {
                    item.classList.remove('not-visible');
                } else {
                    item.classList.add('not-visible');
                }
            });
        }
    }, [selectedCategories]);

    const handleCategoryClick = (slug) => {
        setSelectedCategories((prevCategories) => {
            const newCategories = new Set(prevCategories);
            if (newCategories.has(slug)) {
                newCategories.delete(slug);
            } else {
                newCategories.add(slug);
            }
            return newCategories;
        });
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section className="shop-filters">
            {categories.map(category => (
                <button 
                    key={category.slug} 
                    className={`shop-filters__filter ${selectedCategories.has(category.slug) ? 'active' : ''}`} 
                    onClick={() => handleCategoryClick(category.slug)}
                >
                    {category.name}
                </button>
            ))}
        </section>
    );
};

export default Filters;