import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json'; 
import '../css/Catalog.css'; 

const LabelsSection = () => {
    const [labels, setLabels] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${config.API_BASE_URL}/api/labels-section-items/`)
            .then(response => {
                setLabels(response.data);
            })
            .catch(error => {
                setError(error.message);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section className="shop-label-sections">
            {labels.map(label => (
                <div key={label.slug} className="shop-label">
                    <h2 className="shop-label-title">{label.name}</h2>
                    <div className="shop-label-items-container">
                        <div className="shop-label-items">
                        {label.items.map(item => (
                            <div key={item.slug} className="shop-item">
                                <Link to={`/item/${item.slug}`}>
                                    <img src={item.image_url} alt={item.title} className="shop-item-image" />
                                    <h3 className="shop-item-title">{item.title}</h3>
                                    {item.discount_price ? (
                                        <span className="shop-item-price">
                                            <span className="original-price">{item.price} UAH</span>
                                            <span className="discount-price"><span className="discount-percentage">-{item.sale_percent}%</span>   {item.discount_price} UAH</span>
                                        </span>
                                    ) : (
                                        <span className="shop-item-price">{item.price} UAH</span>
                                    )}
                                </Link>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default LabelsSection;