import React from 'react';
import '../css/Preloader.css'; // You can add custom styles here
import preloader from '../img/preloader.gif';

const Preloader = () => {
    return (
        <div className="preloader">
            <img src={preloader} alt="Loading..." />
        </div>
    );
};

export default Preloader;