import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LabelsSection from './components/LabelsSection';
import FullCatalog from './components/FullCatalog';
import ShortCatalog from './components/ShortCatalog';
import Filters from './components/Filters';
import Preloader from './components/Preloader';
import ItemDetail from './components/ItemDetail';
import Cart from './components/Cart';
import './App.css';

const App = () => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    // Set isRendered to true when the component mounts and is fully rendered
    const handleRender = () => {
      setIsRendered(true);
    };

    // Use a timeout to ensure the render happens after the initial render phase
    const timer = setTimeout(handleRender, 0);

    // Cleanup timeout on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        {!isRendered ? (
          <Preloader />
        ) : (
          <>
            <Header />
            <main className="container">
              <Routes>
                <Route path="/" element={
                  <React.Fragment>
                    <LabelsSection />
                    <ShortCatalog />
                  </React.Fragment>
                } />
                <Route path="/catalog/" element={
                  <React.Fragment>
                    <Filters />
                    <FullCatalog />
                  </React.Fragment>
                } />
                <Route path="/item/:slug/" element={<ItemDetail />} />
                <Route path="/cart/" element={<Cart />} />
              </Routes>
            </main>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
