// ShortCatalog.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json'; 
import '../css/Catalog.css'; 

const ShortCatalog = () => {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${config.API_BASE_URL}/api/catalog/4/`)
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                setError(error.message);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section className="shop-full-catalog">
            <div key="full" className="shop-label">
                <h2 className="shop-label-title">Каталог</h2>
                <div className="shop-label-items-container">
                    <div className="shop-label-items">
                    {items.map(item => (
                        <div key={item.slug} className="shop-item">
                            <Link to={`/item/${item.slug}`}>
                                <div className="shop-item-image-container">
                                    {item.label && (
                                        <div>
                                            <div className="item-label">
                                                {item.label}
                                            </div>
                                            <div className="item-bend right"></div>
                                        </div>
                                    )}
                                    <img src={item.image_url} alt={item.title} className="shop-item-image" />
                                </div>
                                <h3 className="shop-item-title">{item.title}</h3>
                                {item.size_in_stock ? (
                                    item.discount_price ? (
                                        <span className="shop-item-price">
                                            <span className="original-price">{item.price} UAH</span>
                                            <span className="discount-price"><span className="discount-percentage">-{item.sale_percent}%</span>   {item.discount_price} UAH</span>
                                        </span>
                                    ) : (
                                        <span className="shop-item-price">{item.price} UAH</span>
                                    )
                                ) : (
                                    <span className="shop-item-not_in_stock">Немає в наявності</span>
                                )}
                            </Link>
                        </div>
                    ))}
                    </div>
                    <div className='shop-label-link-block'>
                        <Link to="/catalog/" className="shop-label-link">Переглянути весь каталог</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShortCatalog;
