// ItemDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config.json'; 
import '../css/ItemDetail.css';

const ItemDetail = () => {
    const { slug } = useParams(); // Get the slug from URL params
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [selectedSize, setSelectedSize] = useState(0);
    const [cartError, setCartError] = useState(null);
    const [cartSuccess, setCartSuccess] = useState(false);

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/item-details/${slug}/`);
                setItem(response.data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchItem();
    }, [slug]); // Add slug as a dependency

    const handleImageChange = (index) => {
        setCurrentImage(index);
    };

    const handleSizeChange = (index) => {
        setSelectedSize(index);
    };

    const handleAddToCart = async () => {
        if (!item || item.size_in_stock.length === 0) return;

        const cartId = getCartIdFromCookie();

        try {
            const response = await axios.post(
                `${config.API_BASE_URL}/api/cart/add-item/`,
                {
                    cart_uuid: cartId,
                    item_slug: slug,
                    size: item.size_in_stock[selectedSize],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                const data = response.data;
                if (!cartId) {
                    Cookies.set('cart_id', data.cart_uuid, { expires: 365 });
                }

                document.querySelector('.cart-items-count').innerText = `(${data.cart_items_count})`;

                setCartSuccess(true);
                setCartError(null);
            } else {
                throw new Error('Failed to add item to cart');
            }
        } catch (error) {
            setCartError(error.message);
            setCartSuccess(false);
        }
    };

    const getCartIdFromCookie = () => {
        const name = 'cart_id=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!item) {
        return <div>Item not found</div>;
    }

    return (
        <div className="item-detail-container">
            <div className="item-detail-slider">
                <div className="slider-nav">
                    {item.images.map((image, index) => (
                        <button key={index} onClick={() => handleImageChange(index)}>
                            <img src={image} alt={`${item.title} thumbnail`} className={index === currentImage ? 'active' : ''} />
                        </button>
                    ))}
                </div>
                <div className="slider">
                    <img src={item.images[currentImage]} alt={item.title} />
                </div>
            </div>
            <div className="item-detail-order-block">
                <div className="item-detail-block">
                    <div className="item-detail-info">
                        <h4>{item.title}</h4>
                        {item.size_in_stock.length !== 0 ? (
                            item.discount_price ? (
                                <span className="shop-item-price">
                                    <span className="original-price">{item.price} UAH</span>
                                    <span className="discount-price"><span className="discount-percentage">-{item.sale_percent}%</span> {item.discount_price} UAH</span>
                                </span>
                            ) : (
                                <span className="shop-item-price">{item.price} UAH</span>
                            )
                        ) : (
                            <span className="shop-item-not_in_stock">Немає в наявності</span>
                        )}
                        <p>{item.description}</p>
                        <p>Виробник: {item.producer}</p>
                    </div>
                    {item.size.length !== 0 ? (
                        <div className="item-detail-size">
                            {Array.isArray(item.size) && item.size.map((size, index) => {
                                const isSizeInStock = Array.isArray(item.size_in_stock) && item.size_in_stock.includes(size);
                                return (
                                    <button 
                                        key={index} 
                                        onClick={() => isSizeInStock && handleSizeChange(index)} 
                                        className={index === selectedSize ? 'active' : ''} 
                                        disabled={!isSizeInStock}
                                    >
                                        {size}
                                    </button>
                                );
                            })}
                        </div>
                    ) : null}
                    {item.size_in_stock.length !== 0 ? (
                        <button className="add-to-cart" onClick={handleAddToCart}>Додати</button>
                    ) : null}
                    {cartSuccess && <div className="cart-success">Товар додано в кошик</div>}
                    {cartError && <div className="cart-error">Щось пішло не так</div>}
                </div>
            </div>
        </div>
    );
};

export default ItemDetail;
