// Cart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config.json'; 
import '../css/Cart.css';

const Cart = () => {
    const [items, setItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartError, setCartError] = useState(null);
    const [cartSuccess, setCartSuccess] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const fetchCartDetails = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/cart/order/?cart_uuid=${getCartIdFromCookie()}`);
                if (response.status !== 200) {
                    setItems(response.data.cart_items);
                    setTotalPrice(response.data.total_price);
                }
            } catch (error) {
                document.querySelectorAll(".form-input").forEach((input) => {
                    input.disabled = true;
                });
            }
        };

        fetchCartDetails();
    }, []);

    useEffect(() => {
        setIsFormValid(fullName.trim() !== '' && phone.trim() !== '');
    }, [fullName, phone]);

    const handleCreateOrder = async () => {
        const cartId = getCartIdFromCookie();

        try {
            const response = await axios.post(
                `${config.API_BASE_URL}/api/cart/order/`,
                {
                    cart_uuid: cartId,
                    full_name: fullName,
                    phone: phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                setCartSuccess(true);
                setCartError(null);
            } else {
                throw new Error('Failed to create order');
            }
        } catch (cartError) {
            setCartError(cartError.message);
            setCartSuccess(false);
        }
    };

    const getCartIdFromCookie = () => {
        const name = 'cart_id=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <div className="cart-container">
            <form className="cart-form-block">
                <div className="order-form">
                    <div className="total-price">Сума: {totalPrice} UAH</div>
                    <h2 className="order-title">Деталі замовлення</h2>
                    <div className="form-group">
                        <label className="form-label">ФІО:</label>
                        <input 
                            type="text" 
                            className="form-input"
                            value={fullName} 
                            onChange={(e) => setFullName(e.target.value)} 
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Номер телефону:</label>
                        <input 
                            type="text" 
                            className="form-input"
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                        />
                    </div>
                </div>
                {items.length > 0 && (
                    <button 
                        type="button" 
                        className="submit-button"
                        onClick={handleCreateOrder} 
                        disabled={!isFormValid}
                    >
                        Зробити замовлення
                    </button>
                )}
                {cartSuccess && <div className="success-message">Вітаю! Замовлення надіслано менеджеру, скоро з Вами зв'яжуться для обговорення деталей</div>}
                {cartError && <div className="error-message">Щось пішло не так</div>}
            </form>
            <ul className="cart-items">
                {items.map((item, index) => (
                    <li key={index} className="cart-item">
                        <div className="item-image">
                            <img src={item.image_url} alt={item.title} />
                        </div>
                        <div className="item-text-info">
                            <div className="item-title">{item.title}</div>
                            <div className="item-size">{item.size}</div>
                            <div className="item-price">{item.price} UAH</div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Cart;
