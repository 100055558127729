import React from 'react';
import { Link } from 'react-router-dom';
import locationIcon from '../img/white-location-icon.png';
import instagramIcon from '../img/white-instagram-icon.png';
import '../css/Footer.css'; // Importing CSS for styling

function Footer() 
{
    return (
        <React.Fragment>
            <footer id="footer">
                <div className="title">LADY'S CLUB</div>
                <div className="container">
                    <div className="footer-section">
                        <ul>
                            <li><Link to="/catalog/">Каталог</Link></li>
                            <li><a href="#footer">Про нас</a></li>
                            <li><Link to="/cart/">Кошик</Link></li>
                        </ul>
                    </div>
                    <Link className="info-text-block" to="https://www.instagram.com/ladysclub.ua/">
                        <img src={instagramIcon} width="20" alt="InstagramIcon" />
                        <p> ladysclub.ua</p>
                    </Link>
                    <div className='info-text-block'>
                        <img src={locationIcon} width="20" alt="Location" />
                        <p> м.Житомир, ТЦ "ЖИТНІЙ", БУТИК 242</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 Women's Clothing Store. All Rights Reserved.</p>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;